import {Link} from 'react-router-dom'
import Blog from './Blog'

type Props = {
  blogs?: any
  title?: string
  handleDelete ?: any
}
const BlogList = ({blogs, title, handleDelete}: Props) => {


  return (
    <div className={'blog-list'}>
      <h2>{title}</h2>
      {
        blogs.map((blog: Blog) => (
          <div className={'blog-preview'}
               key={blog.id}>
            <Link to={`/blogs/${blog.id}`} >
              <h2>{blog.title}</h2>
            </Link>
          </div>
        ))
      }
</div>
)
}

export default BlogList;