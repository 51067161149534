import {useEffect, useState} from 'react'

const  useFetch = (url: string) =>{

  const [data, setData] = useState<any>([])
  const [isPending, setIsPending] = useState<boolean>(true)

  const [error, setError] = useState<string>()

  useEffect(() => {
    const abortCont = new AbortController()
    setTimeout(() => {
      fetch(url,{signal: abortCont.signal}).then((response) => {
        if (!response.ok) {
          throw Error('could not fetch the data for that resource')
        }
        return response.json()
      }).then((data) => {
        setData(data)
        setIsPending(false)
        setError('')
      }).catch((error) => {
        if (error.name === 'AbortError'){
          console.log('fetch aborted')
        }else {
          setIsPending(false)
          setError(error.message)
        }
      })
    }, 1000);

    return () => abortCont.abort()

  },[url])

  const handleDelete = (id: string) => {
    /*    fetch(`http://localhost:8000/blogs/${id}`, {method: 'DELETE'})
          .then(() => {
            setBlogs(blogs?.filter(blog => blog.id !== id))
            console.log(`Blog with id ${id} deleted`)
          }).catch((error) => {
            console.log(error.message)
          })*/

  }

  return  {data, isPending, error,handleDelete}
}
export  default useFetch;