import {Link} from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="notFound">
      <main>
        <div >
          <h1>404 Not Found</h1>
          <p >
            Sorry, we couldn't find the page you're looking for.
          </p>
        </div>
      </main>
      <footer >
        <Link to={'/'}>
          Return home
        </Link>
      </footer>
    </div>
  )
}

export  default NotFound