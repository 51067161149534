import {useState} from 'react'
import {useHistory} from 'react-router-dom'


const Create = () => {
  const [isPending,setIsPending]= useState<boolean>(false)
  const [title,setTitle]= useState<string>()
  const [body,setBody]= useState<string>()
  const [author,setAuthor]= useState<string>('Keigo')
  const history = useHistory()
  const handleSubmit=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault()
    const blog={title,body,author}
    setIsPending(true)
    fetch('http://localhost:8000/blogs',{
      method:'POST',
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(blog)
    })
    .then(()=>{
      setIsPending(false)
      // go home
      history.push('/')
    })
  }
  return (
    <div className={'create'}>
      <h2>Create a new post</h2>
      <form onSubmit={handleSubmit}>
        <label>Blog title:</label>
        <input type={'text'}
               required={true}
               value={title}
               onChange={(e) => setTitle(e.target.value)}/>
        <label>Blog body:</label>
        <textarea required={true}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}></textarea>
        <label>Blog author:</label>
        <select value={author} onChange={(e) => setAuthor(e.target.value)}>
          <option value={'Keigo'}>Keigo</option>
          <option value={'Mario'}>Mario</option>
          <option value={'Yoshi'}>Yoshi</option>
          <option value={'Toad'}>Toad</option>
          <option value={'Bowser'}>Bowser</option>
        </select>
        {!isPending && <button>Add Blog</button>}
        {isPending && <button disabled>Adding Blog...</button>}
      </form>
    </div>
  )
}

export default Create;