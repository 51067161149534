import BlogList from './BlogList'
import useFetch from './useFetch'


const Home = () => {

  const {data, isPending,error ,handleDelete} =  useFetch('http://localhost:8000/blogs')

  return (
    <div className={'home'}>
      {error && <div>{error}</div>}
      {!isPending && <BlogList blogs={data}
                          title='All Blogs!'
                          handleDelete={handleDelete}></BlogList>}
      {isPending && <div>Loading...</div>}

    </div>
  );
}

export default Home;