const About = () => {
  return (
    <div className={'about'}>
      <h2>Hi there is Keigo👋</h2>
      <hr/>
      <ul>
        <li>🧸 A current college student</li>
        <li>👜 Currently working on Java development related jobs</li>
        <li>😍 Java enthusiasts, Vue users, React beginners</li>
      </ul>
      <h3>🔭 Tech stack</h3>
      <p>
        <img
          src="https://skillicons.dev/icons?i=java,typescript,golang,vue,python,react,mysql"
          alt="Tech stack"
        />
      </p>
      <h3>🛠 Tools I use</h3>
      <p>
        <img
          src="https://skillicons.dev/icons?i=vscode,idea,vercel,cloudflare,git,github,discord,datagrip,DataGrip,typora"
          alt="Tools I use"
        />
      </p>
    </div>
  )
}
export  default About