
import useFetch from './useFetch'
import {useHistory, useParams} from 'react-router-dom'
interface ParamTypes {
  id: string;
}

const BlogDetails = () => {
  const history = useHistory()
  const {id} = useParams<ParamTypes>();
  const {data,error,isPending } = useFetch(`http://localhost:8000/blogs/${id}`)
  const handleClick = () => {
     fetch('http://localhost:8000/blogs/' + id, {
       method: 'DELETE'
     }).then(() => {
       history.push('/')
     })
   }
  return (
    <div className={"blog-details"}>
      {isPending && <div>Loading...</div>}
      {error && <div>{error}</div>}
      {data && (
        <article>
          <h2>{data.title}</h2>
          {data.author && <p>Written by {data.author}</p>}
          <div>{data.body}</div>
          <button onClick={handleClick}>delete</button>
        </article>
      )}
    </div>
  )
}

export default BlogDetails;
